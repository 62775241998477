import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { map} from 'rxjs/operators';
import { FacadeService } from './../services/facade.service';

export interface KeyValuePair {
  key: string|number;
  value: string;
}

@Injectable({
  providedIn: 'root'
})

export class DropdownToLookupService {

  constructor(private facadeService: FacadeService) { }

  
}
