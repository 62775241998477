import { Directive,Input,OnInit,TemplateRef,ViewContainerRef} from '@angular/core';
import { User } from '../core/user';
import { LoginService } from '../services/login-service';

@Directive({
  selector: '[appHasRole]' //*appHasRole
})
export class HasRoleDirective implements OnInit{
  @Input() appHasRole:string[];

user:User
  constructor(private viewContainerRef: ViewContainerRef, private templateRef:TemplateRef<any>, private loginService:LoginService) { 

   this.user= this.loginService.user;
  }
  ngOnInit(): void {
    // clear view if no roles
    if(!this.user?.roles || this.user == null)  
    {
      console.log("no Roles - custom debug code");
    this.viewContainerRef.clear();
      return;
    }
  if(this.user?.roles.some(r=>this.appHasRole.includes(r))){
      this.viewContainerRef.createEmbeddedView(this.templateRef);


}
else{
  console.log("viewContainerRef.clear - custom debug code");
this.viewContainerRef.clear();

}

  }

}
