<mat-sidenav-container>
  <mat-sidenav-content class="homepage">
    <app-header></app-header>
    <div id="maincontent" class="site">
      <app-sidenav style="width: 250px;"></app-sidenav>
      <main class="site-content" *ngIf="this.router.url === '/'">
        <div style="height: 35%;">
          <section class="welcome-section" fxLayout="column" fxFlexFill class="welcome-background">

            <div class="welcome-jumbotron">
              <div fxFlex="30">
                <h1 class="mat-display-1" style="color: white">eXodus Broker Commission App</h1>
              </div>
            </div>
          </section>
        </div>

        <div #linkList id='linkList' class="welcome-info-area">
          <div fxFlex="50">
            <div class="generalBox">
              <dx-html-editor *ngIf="this.generealBoxValue" [value]="generealBoxValue" [readOnly]="true"
                              style="height: 100%; width: 100%; padding-right: 10px; text-align: left; border: none;">
              </dx-html-editor>
            </div>
          </div>
        </div>
      </main>
      <main class="site-content" *ngIf="this.router.url !== '/'">
        <div fxLayout="row" fxFlexFill class="page-container">
          <div fxFlex="100" fxLayoutAlign="start">
            <mat-card fxFlex="100">
              <mat-card-content>
                <router-outlet></router-outlet>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </main>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
