export interface ApiException {
  code: number;
  messages: string[];
}

export interface ServiceException {
  message: string;
  type: ExceptionType;
}

export enum ExceptionType {
  ERROR = 'Error',
  WARNING = 'Warning'
}

export class DataSyncApiErrorResponse {
  errorNumber: number;
  message: string;
  friendlyMessage: string;
}

