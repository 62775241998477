import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration  } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {   AuthenticationResult, InteractionStatus , InteractionType, PopupRequest, RedirectRequest} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import {filter,takeUntil} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { LoginService } from './services/login-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,OnDestroy {
  title = 'eXodus App';
  isIframe = false;
  loginDisplay = false;
  loggedIn = false;
  envBoxText = '';
  envBoxBG = '';
  accountinfo =[];
  username = '';
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig:MsalGuardConfiguration,
                                         private msalBroadcastService:MsalBroadcastService, 
                                        private authService: MsalService,
                                        private router: Router,
                                        private http: HttpClient,
                                        private loginService: LoginService  
                                      
                                       
  
  ) { }

  ngOnInit() {
    this.envBoxText = environment.Settings.EnvironmentBoxText;
    this.envBoxBG = environment.Settings.EnvironmentBoxBG;
 
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
    .pipe(
      
      filter((status:InteractionStatus) => status == InteractionStatus.None),
        takeUntil(this._destroying$)
    )
    .subscribe(()=>{
      this.setLoginDisplay();
      this.checkAndSetActiveAccount();
      this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
    })
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getClaims(claims: any) {
   
    
    let getName = claims ? claims['name'] : null;
     // getName = getName.substr(getName.indexOf(', ') + 1) + ' ' + getName.substr(0, getName.indexOf(','));
     let username = claims ? claims['preferred_username'] : null;
      const user = {
        displayName: getName,
        eid: username.substr(0, username.indexOf('@')),
        email: username,
        roles: Object.assign([], claims.roles)
      };
    this.loginService.user = user;


  }
  login() {

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
        } else {
          this.authService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
      }
    } else {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  setLoginDisplay() {
   
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
   
  }
  ngOnDestroy():void{

    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
