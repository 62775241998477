<mat-nav-list>
  <mat-accordion >
    <mat-expansion-panel >
      <mat-expansion-panel-header>
        Payables
      </mat-expansion-panel-header>
        
          <mat-action-list >
            <button mat-list-item  routerLink="./payable/payable-search"  *appHasRole='["AuthPayableUser","AuthPayableManager","AuthReadOnly"]' >Payable</button>
          </mat-action-list>
          <mat-action-list >
            <button mat-list-item  routerLink="./payable/payable-create-manual"  *appHasRole='["AuthPayableManualCreateManager","AuthPayableManualCreateUser"]' >Create Manual Payables</button>
          </mat-action-list>
      </mat-expansion-panel>
      <mat-expansion-panel >
        <mat-expansion-panel-header>
          Unearned
        </mat-expansion-panel-header>
            <mat-action-list >
              <button mat-list-item  routerLink="./unearned/unearned-flat"  *appHasRole='["AuthPendingPayableUser","AuthPendingPayableManager","AuthReadOnly"]'>Unearned Flat</button>
            </mat-action-list>
            <mat-action-list >
              <button mat-list-item  routerLink="./unearned/unearned-actual-use"  *appHasRole='["AuthUnearnedActualUseUser","AuthUnearnedActualUseManager","AuthReadOnly"]'>Unearned ActualUse</button>
            </mat-action-list>
            <mat-action-list >
              <button mat-list-item  routerLink="./unearned/unearned-prepay"  *appHasRole='["AuthUnearnedPrepayUser","AuthUnearnedPrepayManager","AuthReadOnly"]'>Unearned Prepay</button>
            </mat-action-list>
        </mat-expansion-panel>
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            Payments
          </mat-expansion-panel-header>
              <mat-action-list >
                <button mat-list-item  routerLink="./payment/payment-search"  *appHasRole='["AuthPaymentManager","AuthPaymentUser","AuthReadOnly"]'>Payments</button>
              </mat-action-list>
             
          </mat-expansion-panel>
          <mat-expansion-panel >
            <mat-expansion-panel-header>
              Prepay
            </mat-expansion-panel-header>
                <mat-action-list >
                  <button mat-list-item  routerLink="./unapplied/unapplied-search"  *appHasRole='["AuthUnearnedPrepayActivityManager","AuthUnearnedPrepayActivityUser","AuthReadOnly"]'>Prepay Activities</button>
                </mat-action-list>
               
            </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        MessageLogs
      </mat-expansion-panel-header>
      <mat-action-list>
        <button mat-list-item routerLink="./messagelog/messagelog-search" >Message Logs Search</button>
      </mat-action-list>

    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        PayableStatusOverride
      </mat-expansion-panel-header>
      <mat-action-list>
        <button mat-list-item routerLink="./payablestatusoverride/payablestatusoverride-search" >Payable Status Override Search</button>
      </mat-action-list>

    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        PayableBatchUpdate
      </mat-expansion-panel-header>
      <mat-action-list>
        <button mat-list-item routerLink="./payablebatchupdate/payablebatchupdate-search" >Payable Batch Update</button>
      </mat-action-list>

    </mat-expansion-panel>
    <mat-expansion-panel >
      <mat-expansion-panel-header>
        PartnerWallet
      </mat-expansion-panel-header>
        
          <mat-action-list >
            <button mat-list-item  routerLink="./partnerwallet/partnerwallet-search" *appHasRole='["AuthPartnerWalletReadOnly","AuthReadOnly"]' >PartnerWallet</button>
          </mat-action-list>
      </mat-expansion-panel>
      <mat-expansion-panel >
        <mat-expansion-panel-header>
          ThresholdConfiguration
        </mat-expansion-panel-header>
          
            <mat-action-list >
              <button mat-list-item  routerLink="./thresholdconfiguration/thresholdconfiguration-search" *appHasRole='["AuthPartnerWalletReadOnly","AuthReadOnly"]' >ThresholdConfiguration</button>
            </mat-action-list>
        </mat-expansion-panel>
    </mat-accordion>
  </mat-nav-list>
