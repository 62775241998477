import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUserFavoritePages, UserPreferenceService, IAppInsightData } from 'src/app/core/user-preference.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { LoginService } from 'src/app/services/login-service';
import { DropdownToLookupService } from 'src/app/core/dropdown-to-lookup.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class HomeComponent implements OnInit {
  favoriteListDataSource: IUserFavoritePages[];
  appInsightDataSource: IAppInsightData[];
  showNumberOFLinks = 7;
  applicationId = environment.AppInsightApiId;
  apiKey = environment.AppInsightApiKey;
  userId: string;
  roles: any;
  zipcode: any;
  generealBoxValue;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any =[];
  constructor(public router: Router
    ,         private userPreferenceService: UserPreferenceService
    ,         private httpService: HttpClient,
              private datePipe: DatePipe,
              private titleCasePipe: TitleCasePipe,
              private loginService: LoginService,              
              public dropdownToLookupService: DropdownToLookupService,
              private authService: MsalService, private msalBroadcastService: MsalBroadcastService,
              private titleservice:Title) {
                this.titleservice.setTitle('Exodus-'+environment.defaultenv+'');
                
                if(this.router !== null && this.router.url !== undefined)
                console.log("RouteLink" + this.router.url );
                else
                console.log('Route link is empty');

               }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      console.log(result);
      const payload = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(payload.account);
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(() => {
      this.setLoginDisplay();
      this.checkAndSetActiveAccount();
      this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
    });

    if (this.router.url === '/') {
    this.favoriteListDataSource = [];
    this.appInsightDataSource = [];
    this.userId = this.loginService.getUserId();
     this.roles = this.loginService.getRoles();
    //this.getFavoriteList();
   // this.getAppInsightData();
    
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getClaims(claims: any) {

    
    let getName = claims ? claims['name'] : null;
    // getName = getName.substr(getName.indexOf(', ') + 1) + ' ' + getName.substr(0, getName.indexOf(','));
    let username = claims ? claims['preferred_username'] : null;
    const user = {
      displayName: getName,
      eid: username.substr(0, username.indexOf('@')),
      email: username,
      roles: Object.assign([], claims.roles)
    };
    this.loginService.user = user;


  }
  // getFavoriteList() {
  //   const keyValue = 'favoritePages';
  //   const favPrefLocalStorage = this.userPreferenceService.getUserPreferenceFromLocalStorage(keyValue);

  //   if (favPrefLocalStorage !== undefined && favPrefLocalStorage !== null && favPrefLocalStorage.length > 0) {
  //     this.favoriteListDataSource = JSON.parse(favPrefLocalStorage[0].preference);
  //   }
  // }
 
  getAppInsightData() {
    const query = `pageViews
    | where user_AuthenticatedId == '` + this.userId + `'
    and url != '/app'
    | project  name, url , timestamp
    | where timestamp > ago(7d)
    | order by timestamp desc `;

    const requestURl = 'https://api.applicationinsights.io/v1/apps/' + this.applicationId + '/query?query=' + query;

    const headers = { 'x-api-key': this.apiKey };
    this.httpService.get<any>(requestURl, {headers}).subscribe(data => {
      this.appInsightDataSource  = [];
      data.tables[0].rows.forEach(element => {
        if (this.appInsightDataSource.length < this.showNumberOFLinks) {
        const appInsightData: IAppInsightData = Object.assign({});
        let name = element[1];
        const moduleNames = [
          'payable/',
          
        ];
        moduleNames.forEach(moduleName => {
            if (name.includes(moduleName)) {
                name = name.substr(name.indexOf(moduleName));
                name = name.substr(name.indexOf('/') + 1);
            }
          });
        name = name.replace(/-/g, ' ');
        name = this.titleCasePipe.transform(name);
        const nameExist = this.appInsightDataSource.filter(o => o.name === name);

        if (nameExist.length === 0) {
        appInsightData.name = name;
        appInsightData.url = '#' + element[1];
        appInsightData.lastLoginTime = this.datePipe.transform(element[2], 'dd-MMM-yyyy');
        this.appInsightDataSource.push(appInsightData);
        }
        }
      });

  });
  }

  searchZipZone() {
    if (this.zipcode) {
    this.router.navigate(['app/utility/utility-zip-zone', this.zipcode.toString()]);
    }
  }

  searchZipCode() {
    if (this.zipcode) {
    this.router.navigate(['app/utility/utility-zip', this.zipcode.toString()]);
    }
  }

  // getGeneralBoxMessages() {
  //   this.dropdownToLookupService.getConfigurationByConfigCodeSubCode('HomeScreen', 'GeneralInfo').then((data) => {
  //     if (data && data[0] && data[0].IsActive) {
  //       this.generealBoxValue = data[0].ConfigText;
  //     }
  //   });
  // }

}
