import { Injectable } from '@angular/core';
import { User } from '../core/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  user: User;


  getUserName() {
    return this.user.displayName;
  }

  getSystemId() {
    return 'BPT.CommissionApp.Portal';
  }

  getUserId() {
    return this.user?.eid?.toUpperCase();
  }

  getEmailId() {
    return this.user.email;
  }

  getRoles() {
    return this.user.roles;
  }

  hasAccess(roles:string[]){
    if (this.user.roles === null || this.user.roles === undefined || this.user.roles.length === 0) {
      return false;
    }
    return this.user.roles.some(role=>roles.includes(role));
   
  }
  IsReadOnlyUser():boolean
  {
    if(this.user.roles.filter(x=>x.startsWith("Auth")  ) !== undefined && this.user.roles.filter(x=>x.startsWith("Auth")  ).length === 1  && this.user.roles.findIndex(x=>x ==="AuthReadOnly") !== -1 )
      return true;
      else
      return false;

  }

  checkRoleAccess(role:string)
  {
    if (this.user.roles === null || this.user.roles === undefined || this.user.roles.length === 0) {
      return false;
    }
    console.log (this.user.roles);
    return this.user.roles.map( v => v.toLowerCase()).includes(role.toLowerCase());

  }

  

}
