import { Component, Output, EventEmitter, OnInit} from '@angular/core';

import { LoginService } from 'src/app/services/login-service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Output() closeSidenav = new EventEmitter<void>();
  constructor(public loginService:LoginService) { }
 
  // payableRole: string='AuthPayableUser';
  // isPayableUser:boolean = false;

  ngOnInit(): void {
    // console.log(this.payableRole);
    // this.isPayableUser =  this.loginService.checkRoleAccess(this.payableRole);
    // console.log(this.isPayableUser);
  }
 
  onClose() {
    this.closeSidenav.emit();
  }
}
