import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';


import { protectedResources } from '../auth-config'
import {  Observable, throwError } from 'rxjs';
import { ApiException, ExceptionType, ServiceException } from './api-exception.model';
import { catchError } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
  })
  export class CommissionAppService {
    url = protectedResources.BrokerCommissionFunctionApp.endpoint;
    endpoint = protectedResources.BrokerCommissionFunctionApp.endpoint + '/api/payable/v1/payables';
  
    constructor(private http: HttpClient) { }
  
    getPayables() { 
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*'
        
        })
      }
      return this.http.get<string>(this.endpoint,httpOptions);
    }
  
    // getPayable(id: number) { 
    //   return this.http.get<Payable>(this.url + '/' +  id);
    // }
    
    // postPayable(payable: Payable) { 
    //   return this.http.post<Payable>(this.url, todo);
    // }
  
    // deletePayable(id: number) {
    //   return this.http.delete(this.url + '/' + id);
    // }
  
    // editTodo(payable: Payable) { 
    //  // return this.http.put<Payable>(this.url + '/' + payable.dealId, payable);
    // }
    get(url: string): Observable<any> {
      const options = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('msal.idtoken')}`
        }
      };
  
      return this.http.get<any[]>(url, options).pipe(
        catchError(this.handleError)
      );
    }
 
    post(url: string, payload: any): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('msal.idtoken')}`
        })
      };
  
      return this.http.post<any>(url, payload, httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

  private handleError(error: HttpErrorResponse) {
    const exceptions: ServiceException[] = [];
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        // console.error(
        //   `Backend returned code ${error.status}, ` +
        //   `body was: ${error.error}`);

        if (error.error != null && error.error.error != null) {
          const apiException: ApiException = error.error.error;
          if (apiException.code === 422){
            if (apiException.messages !== null && apiException.messages.length > 0) {
              const errorMessage = apiException.messages.join(', ');
              exceptions.push({
                message: errorMessage,
                type: ExceptionType.ERROR
              });
            }
          }
          if (apiException.code === 401){
            exceptions.push({
              message: 'Sorry!! You are not authorized to perform this action',
              type: ExceptionType.ERROR
            });
          }
        }

        if (error.status && error.status === 401) {
          exceptions.push({
            message: 'Sorry!! You are not authorized to perform this action',
            type: ExceptionType.ERROR
          });
        }
      }
    // Return an observable with a user-facing error message.
    if (exceptions !== null && exceptions.length === 0) {
      exceptions.push({
        message: 'An error occurred, please try again after some time or contact administrator.',
        type: ExceptionType.ERROR
      });
    }
    return throwError(exceptions);
  }

    // getAll() {
    //   return this.http.get<Payable[]>(this.url + '/' + 'getAll');
    // }
  }