import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './login-home/home/home.component';
import { UserPrefrenceResolverService } from './core/user-prefrence-resolver';
import { ErrorComponent } from './shared/error/error.component'

import { MsalGuard } from '@azure/msal-angular';

import { RoleGuardService } from './services/role-guard.service';
import { roles } from './auth-config';

const routes: Routes = [
  {
    path: 'error',
    component: ErrorComponent
  }, 
  {
    path: '',
    // resolve: { userPrefrence: UserPrefrenceResolverService,
    //            authorize: AuthorizeResolverService
    //           },
    component: HomeComponent,
    canActivate: [MsalGuard],
    children: [

      {
        path: 'payable',
        loadChildren: () => import('./payable/payable.module').then(m => m.PayableModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'payable-search',
        loadChildren: () => import('./payable/payable.module').then(m => m.PayableModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'unearned',
        loadChildren: () => import('./unearned/unearned.module').then(m => m.UnearnedModule),
        canActivate: [MsalGuard]

      },
      {
        path: 'payment',
        loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
        canActivate: [MsalGuard]

      },
      {
        path: 'unapplied',
        loadChildren: () => import('./unappliedgroup/unapplied.module').then(m => m.UnappliedModule),
        canActivate: [MsalGuard]

      },
      {
        path: 'messagelog',
        loadChildren: () => import('./messagelog/messagelog.module').then(m => m.MessageLogModule),
        canActivate: [MsalGuard]

      },
      {
        path: 'payablestatusoverride',
        loadChildren: () => import('./payablestatusoverride/payablestatusoverride.module').then(m => m.PayableStatusOverrideModule),
        canActivate: [MsalGuard]

      },
      {
        path: 'payablebatchupdate',
        loadChildren: () => import('./payablebatchupdate/payablebatchupdate.module').then(m => m.PayableBatchUpdateModule),
        canActivate: [MsalGuard]

      },
      {
        path: 'partnerwallet',
        loadChildren: () => import('./partnerwallet/partnerwallet.module').then(m => m.PartnerWalletModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'thresholdconfiguration',
        loadChildren: () => import('./thresholdconfiguration/thresholdconfiguration.module').then(m => m.ThresholdconfigurationModule),
        canActivate: [MsalGuard]
      },

    ]
  },

  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'app',
    pathMatch: 'full'
  },

];
const isIframe = window !== window.parent && !window.opener;


@NgModule({
  imports: [RouterModule.forRoot(routes, {      
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
