import { Injectable } from '@angular/core';
import { LoginService } from '../services/login-service';
import { IUserGridPreferencesOptions } from '../shared/user-preference/user-preference-options';
import { IPreferenecCombined } from '../shared/user-preference/user-preference.model';
import { FacadeService } from './../services/facade.service';
import { Observable } from 'rxjs';
import { ServiceException, ExceptionType } from './../services/api-exception.model';
import { Spinner } from './overlay/spinner';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {

  userId: string;
  systemId: string;
  constructor(private loginService: LoginService,
              private facadeService: FacadeService,
              private spinner: Spinner) {
    this.userId = this.loginService.getUserId();
    this.systemId = this.loginService.getSystemId();
  }

// updateUserPreferenceLocalStorage(): any {
//   return this.facadeService.getUserPreferenceByUserBySystem(this.userId, this.systemId).subscribe((data) => {
//     if (data !== undefined && data !== null) {
//           localStorage.removeItem('userpreference');
//           localStorage.setItem('userpreference', JSON.stringify(data));
//         }
//   });
// }

// getUserPreferenceFromLocalStorage(keyValue: string) {
//   const userSettings = JSON.parse(localStorage.getItem('userpreference'));
//   if (userSettings !== undefined && userSettings !== null && userSettings !== 'null') {
//     const userSet =  userSettings.filter(o => o.keyValue === keyValue);
//     if (userSet !== undefined && userSet !== null) {
//       return userSet;
//     } else {
//       return null;
//     }
//   }
// }

// saveDefaultUserPreference(options: IUserGridPreferencesOptions) {
// const preferenceCombined: IPreferenecCombined = {
//   gridSetting: options.gridPreferences,
//   advanceFilter: options.advanceFilter,
//   buttonSetting: options.buttonPreferences,
//   pivotGridSetting: options.pivotGridSetting ?? options.pivotGridSetting
// };
// const dataToAdd: IUserPreferenceAdd = {
//   UserId: this.loginService.getUserId(),
//   SystemId: this.loginService.getSystemId(),
//   KeyValue: options.keyValue,
//   Preference: JSON.stringify(preferenceCombined),
//   PreferenceName: 'Default',
//   IsDefault: true
// };

// return this.facadeService.updateDefaultUserPreference(dataToAdd);
// }


// the following section is to add/remove fav
// saveFavorite(urlLink: string, favGridName: string) {
//   const keyValue = 'favoritePages';
//   const favPrefLocalStorage = this.getUserPreferenceFromLocalStorage(keyValue);
//   const newFavPageDetails: IUserFavoritePages = {
//     name: favGridName,
//     url: urlLink
//   };

//   if (favPrefLocalStorage !== undefined && favPrefLocalStorage !== null && favPrefLocalStorage.length > 0) {
//     const favList = JSON.parse(favPrefLocalStorage[0].preference);
//     const alreadyExist =   favList.filter( o => o.name === newFavPageDetails.name);
//     if (alreadyExist !== undefined && alreadyExist !== null && alreadyExist.length > 0 ) {
//       const exceptions: ServiceException[] = [];
//       return new Observable((observer) => {
//           exceptions.push({
//             message: 'This grid is already part of your favorite list',
//             type: ExceptionType.WARNING
//           });
//           observer.error(exceptions);
//           observer.complete();
//         });
//     } else {
//       if (favList.length >= 15) {
//         const exceptions: ServiceException[] = [];
//         return new Observable((observer) => {
//             exceptions.push({
//               message: 'Sorry!! You already have 15 grids in your favorite  list',
//               type: ExceptionType.WARNING
//             });
//             observer.error(exceptions);
//             observer.complete();
//           });
//       } else {
//       favList.push(newFavPageDetails);
//       const dataToAdd: IUserPreferenceAdd = {
//         UserId: this.loginService.getUserId(),
//         SystemId: this.loginService.getSystemId(),
//         KeyValue: keyValue,
//         Preference: JSON.stringify(favList),
//         PreferenceName: 'Default',
//         IsDefault: true
//       };

//       return this.facadeService.updateFavoriteUserPreference(dataToAdd);
//     }
//     }
//   } else {
//     const favList: IUserFavoritePages[] = [];
//     favList.push(newFavPageDetails);

//     const dataToAdd: IUserPreferenceAdd = {
//       UserId: this.loginService.getUserId(),
//       SystemId: this.loginService.getSystemId(),
//       KeyValue: keyValue,
//       Preference: JSON.stringify(favList),
//       PreferenceName: 'Default',
//       IsDefault: true
//     };

//     return this.facadeService.updateFavoriteUserPreference(dataToAdd);
//   }
// }




// removeFavorite(favGridName: string) {
//   const keyValue = 'favoritePages';
//   const favPrefLocalStorage = this.getUserPreferenceFromLocalStorage(keyValue);
//   const newFavPageDetails: IUserFavoritePages = {
//     name: favGridName,
//     url: ''
//   };

//   if (favPrefLocalStorage !== undefined && favPrefLocalStorage !== null && favPrefLocalStorage.length > 0) {
//     let favList = JSON.parse(favPrefLocalStorage[0].preference);
//     const alreadyExist =   favList.filter( o => o.name === newFavPageDetails.name);
//     if (alreadyExist !== undefined && alreadyExist !== null && alreadyExist.length > 0 ) {
//       favList = favList.filter( o => o.name !== newFavPageDetails.name);
//       const dataToAdd: IUserPreferenceAdd = {
//         UserId: this.loginService.getUserId(),
//         SystemId: this.loginService.getSystemId(),
//         KeyValue: keyValue,
//         Preference: JSON.stringify(favList),
//         PreferenceName: 'Default',
//         IsDefault: true
//       };

//       return this.facadeService.updateFavoriteUserPreference(dataToAdd);

//     } else {
//       const exceptions: ServiceException[] = [];
//       return new Observable((observer) => {
//           exceptions.push({
//             message: 'This grid is not in your favorite list',
//             type: ExceptionType.WARNING
//           });
//           observer.error(exceptions);
//           observer.complete();
//         });
//     }
// }
// }



}

export interface IUserPreferenceAdd {
  UserId: string;
  SystemId: string;
  KeyValue: string;
  Preference: string;
  PreferenceName: string;
  IsDefault: boolean;
}

export interface IUserFavoritePages {
  name: string;
  url: string;
}

export interface IAppInsightData {
  name: string;
  url: string;
  lastLoginTime: string;
}


