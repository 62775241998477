import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { Injectable, OnDestroy } from '@angular/core';
import { ProgressSpinnerComponent } from 'src/app/shared/progress-spinner/progress-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class Spinner implements OnDestroy {
  private overlayRef: OverlayRef;
  service_count = 0;
  constructor(private overlay: Overlay) { }

  show() {
    this.service_count++;
    Promise.resolve().then(() => {
      if (this.overlayRef !== undefined && this.overlayRef !== null && this.overlayRef.hasAttached()) {
      } else {
        this.overlayRef = this.overlay.create({
          positionStrategy: this.overlay.position().global().left('2000').top('2000'),
          hasBackdrop: false
        });
        this.overlayRef.attach(new ComponentPortal(ProgressSpinnerComponent));
      }
    });
  }

  hide() {
    if (this.service_count > 0) {
      this.service_count--;
    }
    if (this.service_count === 0) {
      Promise.resolve().then(() => {
        if (this.overlayRef !== undefined && this.overlayRef !== null && this.overlayRef.hasAttached()) {
          this.overlayRef.detach();
          this.overlayRef.dispose();
        }
      });
    }
  }

  reset() {
    this.service_count = 0;
    if (this.overlayRef !== undefined && this.overlayRef !== null && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
  }

  ngOnDestroy() {
    this.service_count = 0;
    if (this.overlayRef !== undefined && this.overlayRef !== null && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
  }

}
