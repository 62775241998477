<section class="welcome-section" fxLayout="column" class="welcome-background" fxFill>

  <div fxLayout="column" class="welcome-jumbotron">
    <h1 class="mat-display-3 text-padding" style="color: white">Digital Hub App</h1>
  </div>



  <div fxLayout="row" style="height: 100%" fxLayoutAlign="start start" fxLayoutGap="3%" >

  <h3 class="message">Something went wrong!! Please contact system Administrator.</h3>

</div>



