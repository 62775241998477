// Angular
import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Custom
import { CommissionAppService } from './brokercommissionapp.service';


import { HttpParams } from '@angular/common/http';
import { protectedResources } from '../auth-config';
import { IUserPreferenceAdd } from '../core/user-preference.service';
import { environment } from 'src/environments/environment';




@Injectable({
  providedIn: 'root'
})
export class FacadeService {

  updateDefaultUserPreference: any;
  objectPermissions:Map<string,string[]>;
  private commissionAppSvc: CommissionAppService;


  public get commissionAppService(): CommissionAppService {
    if (!this.commissionAppSvc) {
      this.commissionAppSvc = this.injector.get(CommissionAppService);
    }
    return this.commissionAppSvc;
  }

  

  constructor(private injector: Injector) { }

 

 

  getPermissionByPObjects(): Observable<any> {
    const url = `${protectedResources.BrokerCommissionFunctionApp.endpoint}/v2/permissionbyobjects`;
    return this.commissionAppService.get(url);
  }

  getUdcList(): Observable<any> {
    const url = `${protectedResources.BrokerCommissionFunctionApp.endpoint}/v2/utilities`;
    return this.commissionAppService.get(url);
  }

  getUserPreferenceByUserBySystem(userId, systemId): Observable<any> {
    const url = `${environment.apiEndpoint}/v1/userpreferencesbyuserbysystem?userId=${userId}&systemID=${systemId}`;
    return this.commissionAppSvc.get(url);
  }
  updateFavoriteUserPreference(data): Observable<any> {
    const url = `${environment.apiEndpoint}/v1/userpreferences/favoritelist`;
    return this.commissionAppSvc.post(url, data);
  }


  getpermissionsByObjects ():Map<string,string[]>{
    return this.objectPermissions;
  }
  // getLatestOffer(offerId): Observable<any> {
  //   const url = `${environment.CUDEndPointUrl.General}offercloud/v2/offer/${offerId}/latestoffer`;
  //   return this.enrollmentService.get(url);
  // }

  // getResiOffersCount(request): Observable<any> {
  //   return this.odataService.count(request);
  //   }

  // sendVerificationCode(profileDeviceId): Observable<any> {
  //   const url = `${environment.CUDEndPointUrl.General}offercloud/v2/profiledevices/${profileDeviceId}/sendcode`;
  //   return this.enrollmentService.post(url, {});
  // }

  // sendMessageByState(messageId, state): Observable<any> {
  //   const url = `${environment.CUDEndPointUrl.General}offercloud/v2/messages/${messageId}/sendbystate`;
  //   return this.enrollmentService.post(url, {});
  // }

}



