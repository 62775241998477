<router-outlet></router-outlet>
<!-- <mat-toolbar color="primary">
 





  <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>

</mat-toolbar> -->
<!-- <div class="container">
  This is to avoid reload during acquireTokenSilent() because of hidden iframe 
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div> --> 