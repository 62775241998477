import { protectedResources } from "src/app/auth-config";


export const environment = {
  production: false,
  apiEndpoint:"https://exodus-dev-functions.azurewebsites.net/",
  scopes:["api://bf8a0699-cb6a-4b8b-baaf-9d00672daa49/Access_to_CommissionApp"],
  redirectUri:'https://exodus-commissionapplication-dev.constellation.com/',
  postLogoutRedirectUri:'https://cdn-exodus-dev.azureedge.net/#/log-out',
  clientid:'4b8776a2-d86c-489f-a851-d5bb0a1badf9',
  authority: 'https://login.microsoftonline.com/d8fb9c07-c19e-4e8c-a1cb-717cd3cf8ffe', 

  instrumentationKey: '68d7dcdd-d579-4570-a79d-2dbcee9dd15e',
  AppInsightApiId: '35d8cb73-420f-4478-9f9a-c0b5f7f044fb',
  AppInsightApiKey: 'vctu7ih14btksmx4v1jeh6knvf4u28oeovr2u17h',
  defaultenv:"Dev",
  Settings: {
    AllowEditResponse: true,
    EnvironmentBoxText: 'LOCAL',
    EnvironmentBoxBG: 'environment-box-bg-local'
  },

  
  }