

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    auth: {
        clientId:environment.clientid , // This is the ONLY mandatory field that you need to supply.
        authority: environment.authority, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: environment.redirectUri,//'http://localhost:4200/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: environment.postLogoutRedirectUri,// 'http://localhost:4200/#/log-out', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const protectedResources = {
  BrokerCommissionFunctionApp: {
    endpoint:  environment.apiEndpoint,
    scopes:  environment.scopes
  },
}

export const roles = {
    AuthPayableSystem				   :"AuthPayableSystem" ,
    AuthPayableUser					   :"AuthPayableUser",
    AuthPayableManager				   :"AuthPayableManager",
    AuthUnearnedActualUseSystem		   :"AuthUnearnedActualUseSystem" ,
    AuthUnearnedActualUseUser		   :"AuthUnearnedActualUseUser"	,
    AuthUnearnedActualUseManager	   :"AuthUnearnedActualUseManager"	,
    AuthPendingPayableSystem		   :"AuthPendingPayableSystem"		,
    AuthPendingPayableUser			   :"AuthPendingPayableUser"		,
    AuthPendingPayableManager		   :"AuthPendingPayableManager"		,
    AuthUnearnedPrepaySystem		   :"AuthUnearnedPrepaySystem"		,
    AuthUnearnedPrepayUser			   :"AuthUnearnedPrepayUser"		,
    AuthUnearnedPrepayManager		   :"AuthUnearnedPrepayManager"		,
    AuthPartnerWalletReadOnly          :"AuthPartnerWalletReadOnly",
    AuthPaymentSystem				   :"AuthPaymentSystem"				,
    AuthPaymentUser					   :"AuthPaymentUser"				,
    AuthPaymentManager				   :"AuthPaymentManager"			   ,
    AuthUnearnedPrepayActivitySystem   :"AuthUnearnedPrepayActivitySystem" ,
    AuthUnearnedPrepayActivityUser	   :"AuthUnearnedPrepayActivityUser"   ,
    AuthUnearnedPrepayActivityManager  :"AuthUnearnedPrepayActivityManager",
    AuthThresholdConfigurationUser     :"AuthThresholdConfigurationUser",
    AuthThresholdConfigurationManager : "AuthThresholdConfigurationManager",
    AuthReadOnly : "AuthReadOnly"
}