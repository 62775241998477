import { Component, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from 'src/app/services/app-insight.service';
import { MsalService } from '@azure/msal-angular';
import { LoginService } from 'src/app/services/login-service';
import { alert } from 'devextreme/ui/dialog';
import { DxButtonModule, DxLoadIndicatorModule, DxTemplateModule,  } from 'devextreme-angular';
import { GlobalVariableService } from 'src/app/services/global-variable-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class HeaderComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter<void>();
  userName: string;
  globalSearchText: any;
  resultVisible = true;
  globalSearchValue = '';
  cancelOptions: any;
  loadingVisibleWithCancel = false;
  cancelSearchOptions: any;
  defaultVisible = false;

  constructor(private loginService: LoginService,
              private appInsightService: ApplicationInsightsService,
              private authService: MsalService,         
              private _eref: ElementRef,
              private globalVariableService : GlobalVariableService) {
                this.cancelSearchOptions = {
 
                  text: 'Cancel',
                  type: 'default',             
                  onClick: (e) => {
                      this.globalVariableService.UpdateIsLoadingWithCancel(false);
                  }             
                }
               }


  ngOnInit(): void {
    this.userName = this.loginService.getUserName();
    this.globalVariableService.currentIsLoadingWithCancel.subscribe (c => this.loadingVisibleWithCancel = c)
  }

  onToggleSideNav() {
    this.sidenavToggle.emit();
  }

  onLogOutButtonClick() {
    this.appInsightService.clearUserId();
    localStorage.removeItem('userpreference');
    this.authService.logout();
  }

  rolesClick() {

alert(this.loginService.getRoles().join('<br/>'), 'Roles');
  }

  globalSearchClick = (e) => {
    this.resultVisible = false;
    this.globalSearchValue = this.globalSearchText;
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
    this.resultVisible = true;
    }
   }

   toggleDefault = (e) => {
    this.defaultVisible = !this.defaultVisible;
  }



}
