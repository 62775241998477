import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariableService {

  constructor() {}

  // declare and initialize the quote property
  // which will be a BehaviorSubject
  IsLoadingWithCancel = new BehaviorSubject(false);

  // expose the BehaviorSubject as an Observable
  currentIsLoadingWithCancel = this.IsLoadingWithCancel.asObservable();

  // function to update the value of the BehaviorSubject
  UpdateIsLoadingWithCancel(newIsLoadingWithCancel: boolean){
    this.IsLoadingWithCancel.next(newIsLoadingWithCancel);
  }
}